<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat color="white" dense>
            <v-toolbar-title>CV TEMPLATE</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
        <v-divider class="mx-4"></v-divider>
        <v-data-table :headers="headers" :items="cv_template_list" class="elevation-1" :search="search">
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-text-field v-model="search" append-icon="search" label="Search" single-line
                        hide-details></v-text-field>
                    <span style="width: 20px"> </span>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="dialog = true;">Add CV Template</v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.file_url`]="{ item }">
                <span v-if="item.file_url != null" style="font-size:25px;color:DodgerBlue;">
                  <a :href="item.file_url" target="_blank"><v-icon class="green--text" icon="mdi-eye">mdi-eye {{ item.file_url }}</v-icon></a>        
                </span>
                <span v-else>
                   NA
                </span>
            </template>
            <template v-slot:item.isactive="{ item }">
                <v-switch
                v-model="item.isactive"
                :labels="{ checked: 'On', unchecked: 'Off' }"
                @change="activeswitch(item)">
                </v-switch>
            </template>
            <template v-slot:item.action="{ item }">
                <v-avatar style="" size="25" class="edit-avatar mr-3">
                    <v-icon class="edit-v-icon mr-2" size="18" @click="editItem(item)">mdi-pencil</v-icon>
                </v-avatar>
                <v-icon @click="deleteItem(item)" style="color: red;">mdi-delete</v-icon>
            </template>
        </v-data-table>
        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-form v-model="valid">
                <v-card-title>Add CV Template</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="12">
                                <v-text-field v-model="name" outlined label="Enter CV Template Name"
                                    :rules="[(v) => !!v || 'required']" persistent-hint>
                                    <template #label>
                                        Enter CV Template Name<span style="color: red;"> *</span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="12">
                                <v-file-input v-model="file" prepend-icon="" :prepend-inner-icon="fileIcon" outlined label="Upload CV Template"
                                    :rules="[(v) => !!v || 'required']" persistent-hint  show-size clearable :hint="'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                                    accept=".pdf, .jpg, .jpeg">
                                    <template #label>
                                        Upload CV Template<span style="color: red;"> *</span>
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="dialog=false;"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary darken-1"
                    dark
                    @click="save()"
                    :disabled="!valid"
                    :loading="loader"
                    >Save</v-btn
                  >
                </v-card-actions>
            </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="edit_dialog" max-width="500px">
            <v-card>
                <v-form>
                <v-card-title>Edit CV Template</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="12">
                                <v-text-field v-model="edit_name" outlined label="CV Template Name"
                                    :rules="[(v) => !!v || 'required']" persistent-hint>
                                    <template #label>
                                         CV Template Name<span style="color: red;"></span>
                                    </template>
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="12">
                                <b>Previous CV Template File Name: </b>{{ edit_item.filename }}
                                <v-file-input v-model="edit_file" prepend-icon="" :prepend-inner-icon="fileIcon" outlined label="Upload CV Template"
                                    :rules="[(v) => !!v || 'required']" persistent-hint  show-size clearable :hint="'File Size Should be less than 250 kb,JPEG, JPG & PDF'"
                                    accept=".pdf, .jpg, .jpeg" class="mt-2">
                                    <template #label>
                                        Upload CV Template<span style="color: red;"></span>
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" outlined text @click="edit_dialog=false;"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary darken-1"
                    dark
                    @click="edit(edit_item)"
                    :loading="edit_loader"
                    >Update</v-btn
                  >
                </v-card-actions>
            </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from "axios";
export default {
    data: () => ({
        valid: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        dialog: false,
        cv_template_list: [],
        fileIcon: 'mdi-file',
        file: null,
        name: "",
        loader: false,
        edit_loader: false,
        edit_item: "",
        edit_name: "",
        edit_file: null,
        edit_dialog: false,
        headers: [
            { text: "Sr No.", align: "left", sortable: true, value: "srno"},
            { text: "CV Template Name", align: "left", sortable: true, value: "name"},
            { text: "Attachment", align: "left", sortable: true, value: "file_url"},
            { text: "Is Active", align: "left", sortable: true, value: "isactive"},
            { text: "Action", align: "left", sortable: true, value: "action"},
        ],

    }),
    mounted() {
        this.onLoad();
    },
    methods: {
        showSnackbar(clr, msg) {
        this.snackbar = true;
        this.color = clr;
        this.snackbar_msg = msg;
        },
        onLoad() {
            axios
                .post("/AdminMaster/adminCVTemplate")
                .then((res) => {
                    if (res.data.msg == "200") {
                        if ((this.load = true)) {
                            this.cv_template_list = res.data.cv_template_list;
                        }
                    }
                    else {
                        window.console.log(res.data.msg);
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                    //window.console.log(error);
                })
                .finally(() => {
                    "use strict";
                    var overlay = false;
                });
        },

        save(){
            if (this.name == null) {
                    this.showSnackbar("#b71c1c", "Please Enter CV Template Name!");
                    return
            } 
            if (this.file == null) {
                    this.showSnackbar("#b71c1c", "Please Select CV Template File!");
                    return
            } 

            var file = this.file.name;
                    var ext = file.split(".");
                    ext = ext[ext.length - 1].toLowerCase();
                    var arrayExtensions = ["pdf","jpeg","jpg"];

                    if (arrayExtensions.lastIndexOf(ext) == -1) {
                        // alert("Invalid file.. Only pdf can be uploaded");
                        this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                    return
                    }
            
            //for only allow upload cv when size is 250kb
            const upload = this.file;
            const maxSize = 250 * 1024;
            if(upload.size > maxSize){
                this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                return
            }

            let formData = new FormData();
            formData.append("name", this.name);
            formData.append("file", this.file);
            this.loader = true;
            axios
            .post("/AdminMaster/saveAdminCVTemplate", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
            if (res.data.msg == "200") {
                this.showSnackbar("#4caf50", "CV Template Added Successfully!!!"); // show snackbar on success
                this.dialog = false;
                this.loader = false;
                this.onLoad();
            } else  {
                this.showSnackbar("#b71c1c", res.data.msg); // show snackbar on error
                this.dialog = false;
                this.loader = false;
            }
            })
            .catch((error) => {
                this.loader = false;
                window.console.log(error);
            });
        },

        activeswitch(item) {
            const data = {
                activateitem: item.id,
            };
            axios
            .post("/AdminMaster/activeAdminCVTemplate", data)
            .then((res) => {
            if (res.data.msg == "200") {
                this.showSnackbar("#4caf50", res.data.message);
            } else {
                this.showSnackbar("#b71c1c", "Something Went Wrong...!!!");
            }
            this.onLoad();
            })
            .catch((error) => {
            window.console.log(error);
            });
        },

        deleteItem(item) {
            if(confirm("Are You Sure You Want to Delete CV Template Details?")){
            const data = {
                delete_id: item.id,
            };
            axios
            .post("/AdminMaster/deleteCVTemplate", data)
            .then((res) => {
            if (res.data.msg == "200") {
                this.showSnackbar("#4caf50", res.data.message);
            } else {
                this.showSnackbar("#b71c1c", res.data.error);
            }
            this.onLoad();
            })
            .catch((error) => {
            window.console.log(error);
            });
        }
        },

        editItem(item){
            this.edit_item = item;
            this.edit_name = item.name;
            this.edit_dialog = true;
        },

        edit(item){
            if (this.edit_name == null) {
                    this.showSnackbar("#b71c1c", "Please Enter CV Template Name!");
                    return
            } 
            var file = this.edit_file.name;
                    var ext = file.split(".");
                    ext = ext[ext.length - 1].toLowerCase();
                    var arrayExtensions = ["pdf","jpeg","jpg"];

                    if (arrayExtensions.lastIndexOf(ext) == -1) {
                        // alert("Invalid file.. Only pdf can be uploaded");
                        this.showSnackbar("#b71c1c", "Please Select A only PDF, JPEG & JPG File...");
                    return
                    }
            
            //for only allow upload cv when size is 250kb
            const upload = this.edit_file;
            const maxSize = 250 * 1024;
            if(upload.size > maxSize){
                this.showSnackbar("#b71c1c", "File size exceeds the limit (250kb)");
                return
            }
            let formData = new FormData();
            formData.append("edit_id", item.id);
            formData.append("name", this.edit_name);
            formData.append("file", this.edit_file);
            this.edit_loader = true;
            axios
            .post("/AdminMaster/editAdminCVTemplate", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
            if (res.data.status.code == "SUCCESS") {
                this.showSnackbar("#4caf50", "CV Template Updated Successfully!!!"); // show snackbar on success
                this.edit_dialog = false;
                this.edit_loader = false;
                this.onLoad();
            } else{
                this.showSnackbar("#b71c1c", res.data.error); // show snackbar on error
                this.edit_dialog = false;
                this.edit_loader = false;
            }
            })
            .catch((error) => {
                this.edit_loader = false;
                window.console.log(error);
            });
        },


    },
};
</script>
<style>
.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>